/** Here rests all the base mutation keys for each React Query Mutation */
export const mutations = {
    CreateTradeProfile: 'CreateTradeProfileMutation',
    CreateTradeOrder: 'CreateTradeSellOrderMutation',
    CreateCollectionOrder: 'CreateCollectionOrderMutation',
    CreateUserAccount: 'CreateUserAccount',
    CancelTradeOrder: 'CancelTradeOrderMutation',
    ManagedPortfolio: 'ManagedPortfolio',
    UpdateUserAccount: 'UpdateUserAccount',
    UpdatePortfolioAllocationClassification: 'UpdatePortfolioAllocationClassification',
    UpdateUserPassword: 'UpdateUserPassword',
    UpdateInvestmentPlan: 'UpdateInvestmentPlan',
    UpdateUserProfile: 'UpdateUserProfile',
    LiquidateWines: 'LiquidateWines',
    BulkLiquidateWines: 'BulkLiquidateWines',
    BulkAutolistWines: 'BulkAutolistWines',
    CancelBulkLiquidateWines: 'CancelBulkLiquidateWines',
    UpdateBulkFvmpeg: 'UpdateBulkFvmpeg',
    UpdateFvmpeg: 'UpdateFvmpeg',
    CompleteResetPassword: 'CompleteResetPasswordMutation',
    UpdateUserPreferences: 'UpdateUserPreferences',
    ConfirmDeposit: 'ConfirmDeposit',
    CreateOfferListing: 'CreateOfferListing',
    UsePutOfferListing: 'UsePutOfferListing',
    CancelOfferListing: 'CancelOfferListing',
    PutUserAutoPayUpdate: 'PutUserAutoPayUpdate',
    PutUserAutoPayCancel: 'PutUserAutoPayCancel',
    PostUserLinkedBanks: 'PostUserLinkedBanks',
    PostUserWithdrawal: 'PostUserWithdrawal',
    PostUserTradingWithdrawal: 'PostUserTradingWithdrawal'
};
